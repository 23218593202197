<template>
  <div class="detail-page">
    <template v-if="JSON.stringify(details) != '{}'">
      <div class="cell bg-white border-r4">
        <CommonTitle title="文件名" class="van-hairline--bottom" />
        <van-field v-model="details.file.fileName"> </van-field>
      </div>
      <div class="cell bg-white border-r4 mt-12">
        <CommonTitle title="事项" class="van-hairline--bottom" />
        <van-field
          v-model="details.reason"
          label=""
          placeholder="请填写您需要备注的内容                                                                                                                                         示例：张三报销21年9月差旅费"
          maxlength="36"
          type="textarea"
          rows="3"
          show-word-limit
        />
      </div>
      <div class="btn">
        <van-button type="primary" round @click="handleSubmit">确定</van-button>
      </div>
    </template>
  </div>
</template>

<script>
import CommonTitle from "@components/CommonTitle";
import { getBillInfo, editBill } from "../http/api";
export default {
  name: "CommonDetail",

  components: {
    CommonTitle,
  },

  props: {
    type: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      details: {},
    };
  },

  mounted() {
    this.getInfo();
  },

  methods: {
    getInfo() {
      getBillInfo(this.$route.query.id).then((res) => {
        this.details = res.data;
      });
    },
    handleSubmit() {
      editBill(this.details.id, { ...this.details }).then((res) => {
        if (!res.statusCode) {
          this.$toast(res.message);
          return;
        }
        this.$toast({ message: "保存成功", duration: 5000 });
        this.$router.go(-1);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detail-page {
  padding: 16px 12px;
  .cell {
    overflow: hidden;
    .van-cell {
      padding: 16px;
      img.right-icon {
        width: 17px;
        height: 16px;
        display: block;
      }
    }
  }
  .btn {
    margin-top: 36px;
    padding: 0 28px;
    .van-button {
      width: 100%;
    }
  }
}
</style>
