<template>
  <div class="billDetail">
    <CommonDetail type='bill' />
  </div>
</template>

<script>
import CommonDetail from "@components/CommonDetail";
export default {
  name: "billDetail",

  components: {
    CommonDetail,
  },

};
</script>

<style lang="less" scoped></style>
